// forms

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
// input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  border: 1px solid $form-element-border-color;
  padding: $form-element-padding;
  outline-color:transparent;
  box-shadow:none;
  line-height:1.2;
  color:$black;
  transition:border-color $animation-speed $animation-name;

  // &:hover {

  // }

  &:focus {
    border-color: $form-element-focus-border-color;
    @include placeholder{
      opacity:0;
    }
  }

  @include placeholder {
    color: $placeholder-color;
    transition:opacity 0.25s ease;
  }
  @include media("<tablet"){
    width:100%;
  }
}

input[type="number"]{
  box-shadow: none;
  -webkit-box-shadow:none;
}

input[type="number"],
select {
  -webkit-border-radius: 0;
}

textarea {
  width:100%;
  min-height:150px;
  max-height:300px;
  resize: vertical;
  vertical-align: top;
  @include media("<tablet-small"){
    height:120px;
  }
}

.button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  display:inline-block;
  vertical-align:top;
  font-size:16px;
  line-height:20px;
  font-weight:500;
  height:50px;
  padding:15px 40px;
  max-width:100%;
  background:$btn-background;
  border:0;
  -webkit-appearance: none;
  border-radius:26px;
  cursor: pointer;
  line-height:1.2;
  color:$btn-color;
  outline-color:transparent;
  text-decoration:none;
  transition:color $animation-speed $animation-name, background $animation-speed $animation-name, opacity $animation-speed $animation-name , border-color $animation-speed $animation-name;
  @extend .ellipsis;
  &:hover{
    background:darken($btn-background, 10%);
    color:$btn-color;
  }
  @include media("<laptop"){
    height:44px;
    padding:12px 40px;
  }
  @include media("<desktop"){
    height:40px;
    padding:10px 30px;
  }
  @include media("<tablet"){
    padding:10px 20px;
    font-weight:normal;
    font-size:15px;
  }
}

input[type='file']{
  height:auto;
  padding:0;
  background:none;
  color:inherit;
  text-transform:none;
  &:hover{
    background:none;
    color:inherit;
  }
}