@media print {
	@page {
		margin: 18mm 12mm 15mm;
	}
	.modal-backdrop,
	.modal .close,
	.modal .modal-print {
		display:none !important;
	}
	* {
		background:none !important;
		color:#000 !important;
	}
	.modal-open {
		padding:0;
		.wrapper {
			display:none !important;
		}
		.modal.in {
			display:block !important;
			position:static !important;
			overflow:visible !important;
		}
		.modal-dialog {
			max-width:100%;
			margin:0;
		}
		.modal-content {
			padding:0;
		}
		.list {
			list-style:disc inside;
			li {
				padding:0;
				&:before{
					content:none;
				}
			}
		}
		[href*="tel"],
		[href*="mailto"]{
			font-weight:500;
		}
		h4 {
			page-break-inside: avoid;
		}
	}
}