html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
button{
  -webkit-tap-highlight-color:transparent;
  transition:color 0.2s linear, background 0.2s linear, opacity 0.2s linear , border-color 0.2s linear;
  outline:none;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align:top;
}

// google map fix
.gm-style img {max-width: none}
iframe{
  max-width:100%;
}