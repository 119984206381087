.slick-slider {
	position: relative;
	display: block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	.slick-prev,
	.slick-next{outline: none;}
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
	
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	outline: none;
	display: none;
	position:relative;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
		width:100%;
		outline: none;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow{
	background: none;
	position: absolute;
	top:50%;
	right:0;
	font-size: 22px;
	padding: 0;
	height:34px;
	text-align: center;
	min-width: 0;
	border:0;
	margin: -17px 0 0;
	z-index: 3;
	i{
		display: table;
		width:100%;
		height:100%;
		&:before{
			display: table-cell;
			vertical-align: middle;
		}
	}
	&:hover{
		background: none;
	}
	&.slick-prev{
		right: auto;
		left:0;
	}
	@media only screen and (max-width:1200px){

	}
	@include media("<phone"){

	}
}
.large-slider .slide{
	display: none;
	&:first-child{display: block;}
}
.slick-slider .slide{display: block}


.large-slider{
  position:fixed;
  z-index: -1;
  top:0;
  left:0;
  width:100%;
  height:100%;
  .slick-list,
  .slick-track{
  	height:100%
  }
  .slide{

  }
}

.slick-dots {
	list-style:none;
	margin:0;
	padding:0;
	text-align:center;
	li{
		display:inline-block;
		vertical-align:top;
		margin:0 0 10px;
	}
	button{
		background:rgba(#000,.15);
		border:0;
		margin:0;
		padding:0;
		overflow:hidden;
		text-indent:-9999px;
		font-size:0;
		line-height:0;
		height:2px;
		width:25px;
		min-width:0;
	}
	.slick-active button {
		background:$orange;
	}
}