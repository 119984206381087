// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$light-gray: #595959;
$dark:#183245;
$black: #0b0d16;
$white: #fff;
$light-blue: #2ba0bc;
$orange:#f05722;
$light:#fef6f3;

$main-font: 'Roboto', sans-serif;
$serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$base-text-color: $dark !default;
$base-background-color: $white !default;
$font-size-base: 20px !default;
$line-height-base: 1.2 !default;
$base-font-family: $main-font !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $orange;

// Buttons 
$btn-color: $white !default;
$btn-background: $orange !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 20px !default;
$h5-font-size: 18px !default;
$h6-font-size: 14px !default;

$headings-font-family: $main-font;
$headings-color: $dark !default;


$animation-speed: 0.3s;
$animation-name:linear;
