.modal-open {
  overflow: hidden;
}

.fade {
  opacity:0;
  transition:opacity 0.25s ease;
  &.in {opacity:1;}
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  font-size:14px;
  line-height:1.43;

  &.fade .modal-dialog {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
  }
  &.in .modal-dialog {
    transform: translate(0, 0);
  }

  h3 {
    font-size:24px;
    color: $dark;
    @include media("<tablet"){
      font-size:23px;
      word-wrap:break-word;
    }
  }
  h4 {
    font-size:16px;
    opacity:.9;
    color: $dark;
    margin:0 0 .1em;
  }
  a{
    text-decoration:none;
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 30px auto;
  max-width:620px;
  @include media("<tablet"){
    max-width:700px;
    margin:20px;
  }
}

.modal-dialog.wide {
  max-width:800px;
}

.modal-header {
  padding:0 40px 0 0;
  @include media("<tablet"){
    padding: 25px 0 0;
  }
}

.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  outline: 0;
  padding:20px 30px;
  @include media("<tablet"){
    padding:20px;
    a {
      word-wrap:break-word;
    }
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000;
  // Fade for backdrop
  &.fade {
    opacity:0;
  }
  &.in {
    opacity:.5;
  }
}

.modal .close,
.modal .modal-print {
  position:absolute;
  top:20px;
  right:20px;
  z-index:3;
  margin-top: -2px;
  display:block;
  width:20px;
  height:20px;
  color: $dark;
  background:none;
  margin:0;
  padding:0;
  border:0;
  border:none;
  outline:none;
  @include media("<tablet"){
    padding:3px;
    top:15px;
    right:15px;
  }
  &:hover,
  &:active {
    color: $orange;
  }
}

.modal .modal-print {
  right:55px;
  @include media("<tablet"){
    right:55px;
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}