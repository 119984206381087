$breakpoints: (
  'xs-phone': 360px,
  'phone': 480px,
  'tablet-small': 600px,
  'tablet': 768px,
  'desktop': 1024px,
  'laptop': 1280px,
  'widescreen': 1440px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';
@import 'vendors/normalize';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';

@import 'slider';
@import 'modal';
@import 'loader';
@import 'print';

html,
body {
  height:100%;
}
body{
  min-width:320px;
  @include media("<widescreen"){
    font-size:18px;
  }
  @include media("<tablet"){
    font-size:16px;
  }
}
.wrapper{
  display:table;
  width:100%;
  height:100%;
  table-layout:fixed;
}
.page-content{
  display:table-row;
}
.main-footer{
  display:table-footer-group;
  height:1%;
  background:$black;
  color:#fff;
  a{
    color:#fff;
    text-decoration:none;
    transition:opacity 0.25s ease;
    &:hover,
    &:active{
      opacity:.5;
    }
  }
  .container{
    max-width: 1750px;
    padding-top:1em;
    padding-bottom:1em;
    @include media("<tablet"){
      padding-top:.3em;
      padding-bottom:.3em;
    }
    @include media("<phone"){
      padding-top:0;
    }
  }
}

.align-right {
  float:right;
}

.container{
  max-width: 1500px;
  margin:0 auto;
  padding:0 15px;
  &:after {
    content:'';
    display:table;
    clear:both;
  }
  &.wide{
    max-width:1670px;
  }
  @extend %after;
}

.promo {
  background:#128cc5 url(../images/promo-bg.jpg) 50% 50%/cover no-repeat;
  position:relative;
  color:#fff;
  font-size:22px;
  @include media("<widescreen"){
    font-size:20px;
  }
  @include media("<desktop"){
    font-size:18px;
  }
  @include media("<tablet"){
    font-size:17px;
  }
  &:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(#021424,.55);
  }
  &-holder{
    position:relative;
    z-index:2;
    min-height:100vh;
    padding:100px 0;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align:center;
    .container{
      width:100%;
    }
    @include media("<tablet"){
      .button{
        padding:10px 15px;
      }
    }
  }
  .main-header {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    z-index:3;
    padding:40px 0;
    @include media("<widescreen"){
      padding:25px 0;
    }
    @include media("<tablet"){
      padding:20px 0 0;
    }
    .container {
      max-width:1750px;
    }
  }
  h1{
    font-size:54px;
    color:inherit;
    margin:0 0 .2em;
    br{
      display:none;
      @include media("<desktop"){
        display:block;
      }
    }
    @include media("<widescreen"){
      font-size:46px;
    }
    @include media("<desktop"){
      font-size:6.6vw;
      line-height:1;
      margin:0 0 .3em;
    }
    @include media("<tablet"){
      font-size:9.6vw;
      margin:0 0 .35em;
    }
  }
  p{
    opacity:.7;
  }
  .icon-holder {
    flex:none;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:0 0 50px;
    &:before {
      position:absolute;
      left:50%;
      content:'';
      bottom:0;
      width:1px;
      height:34px;
      background:#fff;
    }
    svg {
      display:block;
      margin:0 auto;
      width:30px;
      height:30px;
    }
  }
}

.main-header {
  .logo {
    float:left;
    width:250px;
    @include media("<tablet"){
      width:160px;
    }
    img{
      display:block;
      width:100%;
    }
  }
  @include media("<tablet"){
    .button {
      font-size:15px;
      color:#fff;
      border:0;
      padding:0;
      text-decoration:none;
      background:none;
      border-radius:0;
      height:auto;
      &:hover,
      &:active{color:$orange;}
    }
  }
}

.heading {
  text-align:center;
  font-size:99px;
  position:relative;
  color:#f6f7f8;
  line-height:1;
  margin:0;
  @include media("<widescreen"){
    font-size:80px;
    margin-bottom:.1em;
  }
  @include media("<desktop"){
    font-size:68px;
  }
  @include media("<tablet"){
    font-size:32px;
    margin:0 0 .3em;
    color:$dark;
  }
  &:before{
    position:absolute;
    bottom:20%;
    font-size:.36em;
    color:$dark;
    left:50%;
    transform: translateX(-50%);
    content:attr(data-text);
    pointer-events:none;
    @include media("<tablet"){
      content:none;
    }
  }
  &-text {
    text-align:center;
    position:relative;
    padding:0 0 0.4em;
    margin:0 0 2.8em;
    @include media("<tablet"){
      padding:0 0 0.2em;
      margin:0 0 2.5em;
    }
    &:before{
      content:'';
      position:absolute;
      bottom:0;
      left:50%;
      height:2px;
      background:$orange;
      width:36px;
      margin:0 0 0 -18px;
    }
    p{
      opacity:.7;
    }
  }
}

.block {
  display:flex;
  flex-wrap:wrap;
  text-align:center;
  justify-content:center;
  margin:0 auto;
  @include media("<widescreen"){
    max-width:1000px;
  }
  &-item {
    width:16.66%;
    padding:0 10px 15px;
    font-size:16px;
    line-height:1.38;
    @include media("<widescreen"){
      width:29%;
      padding:0 15px 40px;
    }
    @include media("<tablet"){
      width:50%;
      padding:0 10px 40px;
    }
    svg {
      display:block;
      width:70px;
      height:70px;
      margin:0 auto 1.2em;
      color: #2f4b5e;
      transition:transform 0.4s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    &-title{
      display:block;
      font-size:20px;
      line-height:1.2;
      font-weight:500;
      @include media("<widescreen"){
        font-size:18px;
      }
      @include media("<desktop"){
        font-size:17px;
      }
      @include media("<tablet"){
        font-size:15px;
      }
    }
    p{
      margin:0.9em 0;
      opacity:.9;
    }
  }
  &.wide {
    @include media("<widescreen"){
      max-width:870px;
    }
    @include media("<tablet"){
      flex-direction:column;
    }
    .block-item {
      width:25%;
      padding:0 3% 15px;
      @include media("<widescreen"){
        width:50%;
        padding:0 50px 50px;
      }
      @include media("<tablet"){
        width:100%;
        padding:0 15px 30px;
      }
      svg {
        width:60px;
        height:60px;
      }
    }
  }
}

.section {
  padding:5.9em 0;
  @include media("<widescreen"){
    padding:4em 0;
  }
  @include media("<desktop"){
    padding:3em 0;
  }
  & + .section {
    padding-top: .2em
  }
}
.cta-section {
  padding:120px;
  position:relative;
  text-align:center;
  color:#fff;
  background:#128cc5 url(../images/cta-bg.jpg) 50% 50%/cover no-repeat;
  @include media("<widescreen"){
    padding:100px 80px;
  }
  @include media("<desktop"){
    padding:80px 40px;
  }
  @include media("<tablet"){
    padding:40px 15px;
  }
  &:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(#021424,.55);
  }
  .container{
    position:relative;
    z-index:2;
  }
  h2,h3{
    color:inherit;
    font-size:36px;
    @include media("<desktop"){
      font-size:32px;
    }
    @include media("<tablet"){
      font-size:26px;
    }
  }
}

.tile {
  &-block {
    display:flex;
    flex-wrap:wrap;
    max-width:1590px;
    margin:0 auto;
    @include media("<desktop"){
      max-width:600px;
    }
    @include media("<tablet"){
      flex-direction:column;
      max-width:300px;
    }
  }
  &-column{
    padding:0 18px 20px;
    width:25%;
    @include media("<widescreen"){
      padding:0 20px 20px;
    }
    @include media("<desktop"){
      width:50%;
      padding:0 15px 40px;
    }
    @include media("<tablet"){
      width:100%;
    }
  }
  &-item{
    &-image{
      margin:0 0 26px;
      box-shadow: 0 5px 13px rgba(24, 50, 69, 0.26);
      border-radius: 12px;
      background-color: #fff;
      @include media("<widescreen"){
        margin:0 0 20px;
      }
      img{
        display:block;
        width:100%;
        transition: transform 0.4s ease;
      }
    }
    &-visual {
      overflow:hidden;
      border-radius: 12px 12px 0 0;
    }
    figcaption{
      padding:15px;
      font-size:22px;
      font-weight:500;
      @extend .ellipsis;
      @include media("<widescreen"){
        padding:10px;
        font-size:18px;
      }
    }
    a {
      text-decoration:none;
      color: $dark;
      &:hover,
      &:active {
        color: $orange;
        img {
          transform:scale(1.1);
        }
      }
    }
    &-link{
      display:inline-block;
      vertical-align:top;
      color: $dark;
      font-size:16px;
      text-decoration:none;
      margin:0 0 0 16px;
      @include media("<widescreen"){
        margin:0 0 0 10px;
        font-size:15px;
      }
      &:hover,
      &:active{
        color:$orange;
      }
      &:after{
        content:' ›';
        margin:0 0 0 .3em;
      }
    }
  }
}

.user-section {
    @include media("<tablet"){
      display:none !important;
    }
}

.image-block {
  position:relative;
  margin:0 auto 3em;
  max-width:1390px;
  border:50px solid transparent;
  border-width:0 50px;
  @include media("<tablet") {
    max-width:200px;
  }
  &-bg{
    display:block;
    width:100%;
  }
  .lines {
    display:block;
    width:100%;
  }
  [class*="user"]{
    position:absolute;
    z-index:2;
    border-radius:50%;
    border: 4px solid $orange;
    background:$orange;
    width:108px;
    height:auto;
    margin:-54px 0 0 -54px;
    @include media("<widescreen"){
      width:94px;
      margin:-47px 0 0 -47px;
    }
    @include media("<desktop"){
      border-width:2px;
      width:76px;
      margin:-38px 0 0 -38px;
    }
  }
  .user-01 {
    top:56%;
    left:0;
  }
  .user-02 {
    top:7.1%;
    left:12.4%;
  }
  .user-03 {
    top:90%;
    left:23%;
  }
  .user-04 {
    top:23.1%;
    left:34.5%;
  }
  .user-05 {
    top:85%;
    left:46.5%;
  }
  .user-06 {
    top:72.5%;
    left:63.3%;
  }
  .user-07 {
    top:15.4%;
    left:76.7%;
  }
  .user-08 {
    top:95.5%;
    left:86.8%;
  }
  .user-09 {
    top:43.7%;
    left:99.2%;
  }
}

.map-wrapper {
  @include media("<tablet"){
    overflow:hidden;
    margin:0 -15px 1em;
  }
}
.map-block {
  position:relative;
  max-width:1400px;
  margin:0 auto 1em;
  @include media("<tablet"){
    margin:0 -75px;
  }
  @include media("<xs-phone"){
    margin:0 -50px;
  }
  img {
    display:block;
    width:100%;
  }
  [class*="point"]{
    position:absolute;
    z-index:2;
    width:26px;
    @include media("<laptop"){
      margin:-5px 0 0 -2px;
    }
    @include media("<desktop"){
      margin:-2px 0 0 -2px;
      width:18px;
    }
    @include media("<phone"){
      margin:-6px 0 0 -4px;
    }
    svg{
      display:block;
      width:100%;
      color: $orange;
    }
  }
  .point-01 {
    top:29.7%;
    left:20.2%;
    @include media("<phone"){
      top:27.4%;
      left:19.9%;
    }
  }
  .point-02 {
    top:15.9%;
    left:43.1%;
  }
  .point-03 {
    top:16.8%;
    left:45.9%;
  }
  .point-04 {
    top:18.7%;
    left:52.4%;
  }
  .point-05 {
    top:71.7%;
    left:82.4%;
  }
  .point-06 {
    top:31.6%;
    left:52.8%;
    @include media("<laptop"){
      top:30.6%;
    }
  }
}

.contact {
  &-block{
    max-width:920px;
    max-width:1100px;
    margin:0 auto;
    display:flex;
    flex-wrap:wrap;
    font-size:16px;
    line-height:1.5;
    @include media("<desktop"){
      max-width:640px;
    }
    @include media("<tablet"){
      flex-direction:column;
    }
    &-title{
      display:block;
      font-size:20px;
      font-weight:500;
      margin:0 0 0.8em;
      &:only-child{margin:0;}
    }
    a{
      color: inherit;
      text-decoration:none;
      &:hover,
      &:active {
        color: $base-link-color;
      }
    }
  }
  &-column{
    width:33.33%;
    width:25%;
    padding:0 15px 12px;
    @include media("<desktop"){
      width:50%;
    }
    &.wide {
      width:50%;
      @include media("<tablet"){
        width:100%;
      }
    }
    @include media("<widescreen"){
      p{margin:0 0 .5em;}
    }
    @include media("<tablet"){
      width:100%;
    }
  }
}

.form-section {
  position:relative;
  color:#fff;
  background:#128cc5 url(../images/form-bg.jpg) 50% 50%/cover no-repeat;
  padding:70px 0 100px;
  @include media("<widescreen"){
    padding:50px 0 70px;
  }
  @include media("<tablet"){
    padding:40px 0;
  }
  &:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(#021424,.55);
  }
  .container{
    position:relative;
    z-index:2;
  }
  .heading{
    color:rgba(255,255,255,.11);
    margin:0 0 0.72em;
    @include media("<tablet"){
      color:rgba(255,255,255,.5);
    }
    &:before{
      color:#fff;
      bottom:17%;
    }
    &:after{
      content:'';
      position:absolute;
      bottom:-23px;
      left:50%;
      height:2px;
      background:$orange;
      width:36px;
      margin:0 0 0 -18px;
    }
  }
}

.contact-form {
  max-width:490px;
  margin:0 auto 1em;
  @include media("<tablet"){
    margin:3.2em auto 0;
  }
  input[type="submit"]{
    display:block;
    width:100%;
  }
}

.form-holder {
  background:#fff;
  color:$dark;
  border-radius:22px;
  padding:50px;
  margin-bottom:10px;
  @include media("<widescreen"){
    padding:40px;
  }
  @include media("<tablet"){
    padding:30px;
  }
}

.input-row {
  position:relative;
  margin:0 0 35px;
  @include media("<widescreen"){
    margin:0 0 25px;
  }
  label{
    font-size:16px;
    line-height:1;
    font-weight:normal;
    display:block;
    cursor:pointer;
    margin:0 0 5px;
    font-weight:500;
  }
  input[type='text'],
  input[type='email'],
  textarea{
    display:block;
    width:100%;
    margin:0;
    border:0;
    border-bottom:1px solid rgba(#000,.2);
    font-size:16px;
    line-height:22px;
    padding:6px 0;
    height:35px;
  }
  textarea {
    min-height:116px;
    max-height:204px;
    padding:0 0 5px;
    margin-top:10px;
  }
}

.preview-section {
  padding:5.7em 0;
  text-align:center;
  @include media("<widescreen"){
    padding:4em 0;
  }
  @include media("<tablet"){
    padding:40px 0;
  }

  h3{
    font-size:36px;
    line-height:1.22;
    margin:0 0 1.4em;
    @include media("<widescreen"){
      font-size:30px;
      margin:0 0 1.2em;
    }
    @include media("<tablet"){
      font-size:26px;
      margin:0 0 .5em;
    }
  }
}

.footer {
  &-block {
    display:flex;
    padding:35px 0;
    font-weight:normal;
    font-size:16px;
    @include media("<widescreen"){
      padding:25px 0;
    }
    @include media("<desktop"){
      font-size:15px;
      padding:20px 0;
    }
    @include media("<tablet"){
      padding:15px 0;
      font-size:14px;
      line-height:1.6;
    }
    @include media("<phone"){
      flex-direction: column;
    }
    & + .footer-block {
      border-top:1px solid rgba(#d8d8d8, .5);
      @include media("<laptop"){
        flex-direction:column;
        .footer-column {
          width:100%;
          padding:0;
        }
        p{margin:0;}
      }
      @include media("<tablet"){
        word-spacing:.1em;
        .footer-column {
          text-align:center;
        }
      }
    }
    p{margin:0;color:rgba(#fff,.3);}
    a{
      color: inherit;
      transition:color 0.25s ease;
      &:hover,
      &:active{
        color:#fff;
        opacity:1;
      }
    }
  }
  &-column {
    width:50%;
    padding:0 15px 0 0;
    @include media("<phone"){
      width:100%;
      padding:0;
      text-align:center;
    }
    &:nth-child(even) {
      padding:0 0 0 15px;
      text-align:right;
      @include media("<phone"){
        padding:0;
        text-align:center;
      }
    }
  }
  &-logo {
    display:block;
    max-width:250px;
    margin:0 auto 0 0;
    @include media("<tablet"){
      margin-top:6px;
    }
    @include media("<phone"){
      display:none;
    }
  }
}

.social-networks {
  list-style:none;
  margin:0;
  padding:0;
  li {
    display:inline-block;
    vertical-align:top;
    margin:0 0 0 60px;
    @include media("<widescreen"){
      margin:0 0 0 40px;
    }
    @include media("<desktop"){
      margin:0 0 0 20px;
    }
    @include media("<tablet"){
      margin:0 0 0 10px;
    }
  }
  svg {
    display:block;
    width:24px;
    height:24px;
    margin:0;
    color:#fff;
    @include media("<desktop"){
      width:20px;
      height:20px;
    }
  }
  a{
    display:block;
    padding:5px;
    transition:opacity 0.25s ease;
    &:hover {
      opacity:.7;
    }
  }
}

.slick {
  &-slider {
    padding:0 110px;
    @media only screen and (max-width:1669px){
      padding:0 50px;
    }
    @include media("<widescreen"){
      padding:0 35px;
    }
    @include media("<tablet"){
      max-width:360px;
      margin:0 auto;
    }
  }
  &-slide {
    padding:0 40px;
    @media only screen and (max-width:1669px){
      padding:0 10px;
    }
    img{
      display:block;
      width:100%;
      transition:transform 0.4s ease;
    }
    &-title{
      display:block;
      font-size:20px;
      font-weight:500;
      text-align:center;
      @include media("<widescreen"){
        font-size:18px;
      }
    }
    &-image {
      overflow:hidden;
      border-radius:8px;
      max-width:204px;
      margin:0 auto 20px;
      @include media("<tablet"){
        margin-bottom:10px;
        max-width:230px;
      }
      &:hover {
        img {
          transform:scale(1.1);
        }
      }
    }
  }
  &-dots {
    @include media("<tablet"){
      margin-top:10px;
    }
  }
}

.slick-next,
.slick-prev {
  svg {
    display:block;
    width:24px;
    height:24px;
    color: rgba(#183245,.2);
    transition:color 0.25s ease;
  }
  &:hover,
  &:active {
    svg {
      color:$orange;
    }
  }
}

.slick-prev {
  transform:scaleX(-1);
}

.industry-slider .slick-next,
.industry-slider .slick-prev {
  margin-top:-56px;
  @include media("<widescreen"){
    margin-top:-53px;
  }
}

.project-slider .slick-next,
.project-slider .slick-prev {
  margin-top:-69px;
  @include media("<widescreen"){
    margin-top:-63px;
  }
}

.project-slider .slick-slide {
  padding:0 18px 20px;
  @include media("<widescreen"){
    padding:0 20px 20px;
  }
  @include media("<desktop"){
    padding:0 10px;
  }
}

.address {
  font-style:normal;
  margin:0 0 1.2em;
  a{
    color: inherit;
    text-decoration:none;
    &:hover,
    &:active {
      color: $orange;
    }
  }
}

.form_result{
  display: none;
  text-align: center;
  font: 25px/1.43 Arial, Helvetica, sans-serif;
  padding: 5% 10%;
  font-size: 25px;
  width: 100%;
  left: 0;
}

.form_result.error {
  text-align: center;
  font-size: 14px;
  padding: 20px 10% 10px 10%;
}

.error {
  color: red;
}

.js-form-errors{
  margin: 0;
  padding: 15px 0 0 0;
  color: #d32f2f;
  width: 100%;
  clear: both;
  font-family: FranklinGothicMediumCondRegul,Arial,Helvetica,sans-serif;
  list-style-type: none;
  li{
    padding: 0 0 15px;
  }
}

.modal {
  ol {
    margin:0;
    padding:0;
    list-style:none;
    counter-reset: order-counter;
    & > li {
      &:before {
        counter-increment: order-counter;
        content: counter(order-counter) ". ";
        font-size:16px;
        opacity:.9;
        color: $dark;
        margin:0 0 .1em;
        font-weight: bold;
        line-height: 1.2;
      }
      & > h4 {
        display: inline;
      }
    }
    p {
      margin: 1em 0;
    }
    .address {
      margin-top:1em;
    }
  }
}

.alpha {
  list-style:none;
  margin: 1em 0;
  padding: 0;
  counter-reset: alpha-counter;
  & > li {
    padding:0;
    &:before {
      float:left;
      counter-increment: alpha-counter;
      content: counter(alpha-counter, lower-alpha) ") ";
      margin:0 8px 0 0;
    }
    &:after {
      display:block;
      content:'';
      clear:both;
    }
  }
}

.roman{
  list-style:none;
  margin: 1em 0;
  padding: 0;
  counter-reset: roman-counter;
  & > li {
    padding:0;
    &:before {
      float:left;
      counter-increment: roman-counter;
      content: counter(roman-counter, lower-roman) ") ";
      margin:0 8px 0 0;
    }
    &:after {
      display:block;
      content:'';
      clear:both;
    }
  }
}

.list {
  list-style:none;
  margin:1em 0 1em 1em;
  padding:0;
  & > li {
    padding:0 0 0 12px;
    position:relative;
    &:before {
      content:'';
      position:absolute;
      top:.5em;
      left:0;
      width:4px;
      height:4px;
      border-radius:50%;
      background:currentColor;
    }
  }
}

.mobile-hidden {
  @include media("<tablet"){
    display:none !important;
  }
}

.mobile-show {
  display:none !important;
  @include media("<tablet"){
    display:block !important;
  }
}