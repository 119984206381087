// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  font-family: $headings-font-family;
  font-weight: bold;
  margin: 0 0 0.8em;
  color: $headings-color;
  line-height:1.2;
  @include media("<laptop"){
    margin-bottom:.5em;
  }
}

h1, .h1 {
  font-size: $h1-font-size;
}

h2, .h2 {
  font-size: $h2-font-size;
}

h3, .h3 {
  font-size: $h3-font-size;
}

h4, .h4 {
  font-size: $h4-font-size;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 $vertical-rhythm;
}

a {
  color: $base-link-color;
  &:hover,
  &:focus {
    text-decoration: none;
    color:darken($base-link-color, 15%);
  }
}

@include media("<tablet"){
  h1, .h1 {
    font-size: 24px;
  }

  h2, .h2 {
    font-size: 22px;
  }

  h3, .h3 {
    font-size: 20px;
  }

  h4, .h4 {
    font-size: 18px;
  }

  h5, .h5 {
    font-size: 16px;
  }
}